
import { defineComponent, onMounted, ref, computed } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";
import { ElMessage } from "element-plus";
import JwtService from "@/core/services/JwtService";
import Quill from "quill";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);

interface Document {
  title: string;
  subject: string[];
  level: number[];
  description: string;
  resources: any[];
  coverPicture: any;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface Level {
  value: number;
  label: string;
  schoolTypes: string[];
}

interface UpdateDocumentPayload {
  title: string;
  subject: string[];
  level: number[];
  description: string;
  deleteCoverPicturePermanently: boolean;
  deleteCoverPicture: boolean;
  deletefiles?: number[];
}

export default defineComponent({
  name: "edit-document",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const cancelButtonRef = ref<null | HTMLButtonElement>(null);
    const loadingSktelton = ref(true);
    const upload = ref<any>(null);
    const uploadCover = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const uploadPercentage = ref(0);
    const documentID = route.params.id;
    const deleteCoverPicture = ref(false);
    const deleteCoverPicturePermanently = ref(false);
    const deleteFileIndices = ref<number[]>([]);
    const originalFiles = ref<string[]>([]);
    const document = ref<Document>({
      title: "",
      subject: [],
      level: [],
      description: "",
      resources: [],
      coverPicture: "",
    });

    const allLevels = ref<Level[]>([
      {
        value: -2,
        label: t("document.levels.niveauPre"),
        schoolTypes: ["jardinEnfant", "ecole", "primaireEtCollege"],
      },
      {
        value: -1,
        label: t("document.levels.niveau0"),
        schoolTypes: ["jardinEnfant", "ecole", "primaireEtCollege"],
      },
      {
        value: 1,
        label: t("document.levels.niveau1"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 2,
        label: t("document.levels.niveau2"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 3,
        label: t("document.levels.niveau3"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 4,
        label: t("document.levels.niveau4"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 5,
        label: t("document.levels.niveau5"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 6,
        label: t("document.levels.niveau6"),
        schoolTypes: ["ecole", "primaireEtCollege"],
      },
      {
        value: 7,
        label: t("document.levels.niveau7"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 8,
        label: t("document.levels.niveau8"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 9,
        label: t("document.levels.niveau9"),
        schoolTypes: ["college", "collegeEtLycee", "primaireEtCollege"],
      },
      {
        value: 10,
        label: t("document.levels.niveau10"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 11,
        label: t("document.levels.niveau11"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 12,
        label: t("document.levels.niveau12"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
      {
        value: 13,
        label: t("document.levels.niveau13"),
        schoolTypes: ["lycee", "collegeEtLycee"],
      },
    ]);

    const levels = computed(() =>
      allLevels.value.filter((level) =>
        level.schoolTypes.includes(store.getters.serverConfigUrl.building.type)
      )
    );

    const editorOptions = {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["link"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
        ],
        magicUrl: true,
      },
    };

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const fetchSubjects = async () => {
      ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: trimester,
        },
      })
        .then(({ data }) => {
          let subjectList: Subject[] = [];
          for (const classroom of data) {
            subjectList.push(...classroom.subjects);
          }

          //remove duplicated
          subjectList = subjectList.filter(
            (subject, index) =>
              subjectList.findIndex((s) => s._id == subject._id) === index &&
              !modules.value.find((m) =>
                m.subjects.find((ss) => ss._id == subject._id)
              ) &&
              subject.status !== "inactive"
          );

          subjects.value = subjectList;
        })
        .catch((e) => console.log(e));
    };

    const getDocumentDetails = async () => {
      await ApiService.get(`/library/item/${documentID}`)
        .then(async ({ data }) => {
          originalFiles.value = data.files || [];

          document.value = {
            title: data.title || "",
            subject: data.subject
              ? data.subject.map((subject: Subject) => subject._id)
              : [],
            level: data.level,
            description: data.description || "",
            coverPicture: data.coverPicture
              ? {
                  name: getFileName(data.coverPicture),
                  url: data.coverPicture,
                  storedName: data.coverPicture,
                }
              : null,
            resources: data.files
              ? data.files.map((file: string) => ({
                  name: getFileName(file),
                  url: file,
                  storedName: file,
                }))
              : [],
          };
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingSktelton.value = false;
        });
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.array()
        .min(1, t("course.requiredSubject"))
        .required(t("course.requiredSubject")),
      level: Yup.array()
        .min(1, t("course.requiredLevels"))
        .required(t("course.requiredLevels")),
      resources: Yup.mixed().test(
        "required-resources",
        t("document.requiredResources"),
        () => document.value.resources.length > 0
      ),
    });

    const getFileName = (file: string): string => {
      try {
        const decodedFile = decodeURIComponent(file);

        const segments = decodedFile.split("/");
        const fullFileName = segments.pop() || decodedFile;

        return fullFileName.replace(/^\d+-/, "");
      } catch (error) {
        console.error("Error decoding short file name:", error);
        return file;
      }
    };

    const validateCoverPicture = (file: File) => {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

      if (!allowedTypes.includes(file.type)) {
        ElMessage.error(t("document.invalidFileType"));
        return false;
      }

      document.value.coverPicture = {
        name: file.name,
        raw: file,
        url: URL.createObjectURL(file),
      };

      deleteCoverPicture.value = true;
      deleteCoverPicturePermanently.value = false;
      return true;
    };

    const customHttpRequest = () => {
      return true;
    };

    const removeCoverPicture = () => {
      deleteCoverPicturePermanently.value = true;
      deleteCoverPicture.value = false;

      document.value.coverPicture = null;

      if (uploadCover.value?.clearFiles) {
        uploadCover.value.clearFiles();
      }
    };

    const handleExceedCover = (files) => {
      if (uploadCover.value && uploadCover.value.clearFiles) {
        uploadCover.value.clearFiles();
      }

      const newFile = files[0];

      if (validateCoverPicture(newFile)) {
        document.value.coverPicture = newFile;
        deleteCoverPicture.value = true;
        deleteCoverPicturePermanently.value = false;
        uploadCover.value?.handleStart(newFile);
      }
    };

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("document.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    const handleFileChange = (file, fileList) => {
      const removedFiles = document.value.resources.filter(
        (resource) =>
          resource.url && !fileList.find((f) => f.name === resource.name)
      );

      removedFiles.forEach((file) => {
        const index = originalFiles.value.indexOf(file.url);
        if (index !== -1 && !deleteFileIndices.value.includes(index)) {
          deleteFileIndices.value.push(index);
        }
      });

      const existingFiles = document.value.resources.filter(
        (resource) =>
          resource.url && fileList.find((f) => f.name === resource.name)
      );

      const newFiles = fileList
        .filter(
          (f) => !existingFiles.find((existing) => existing.name === f.name)
        )
        .map((f) => ({
          name: f.name,
          raw: f.raw || null,
          url: f.url || null,
        }));

      document.value.resources = [...existingFiles, ...newFiles];
    };

    const handleFileRemove = (file: any) => {
      // If it's an existing file (has url), find its index in original files
      if (file.url) {
        const index = originalFiles.value.indexOf(file.url);
        if (index !== -1) {
          deleteFileIndices.value.push(index);
        }
      }
    };

    const isSubmitting = ref(false);

    onMounted(async () => {
      await fetchSubjects();
      await getDocumentDetails();
    });

    const resetAfterSubmit = () => {
      deleteFileIndices.value = [];
      deleteCoverPicture.value = false;
      deleteCoverPicturePermanently.value = false;
    };

    const editDocumentNoFiles = async () => {
      const payload: UpdateDocumentPayload = {
        title: document.value.title,
        subject: document.value.subject,
        level: document.value.level,
        description: document.value.description?.trim() || "",
        deleteCoverPicture: deleteCoverPicture.value,
        deleteCoverPicturePermanently: deleteCoverPicturePermanently.value,
      };
      if (deleteFileIndices.value.length > 0) {
        payload.deletefiles = deleteFileIndices.value;
      }

      return submitEdit(payload);
    };

    const editDocumentWithFiles = async () => {
      const data = new FormData();

      data.append("title", document.value.title);
      document.value.subject.forEach((sub) => {
        data.append("subject[]", sub);
      });
      document.value.level.forEach((level) => {
        data.append("level[]", level.toString());
      });
      if (
        document.value.description &&
        document.value.description.trim() !== ""
      ) {
        data.append("description", document.value.description);
      }

      document.value.resources.forEach((resource) => {
        if (resource.raw) {
          data.append("files", resource.raw);
        }
      });

      if (deleteFileIndices.value.length > 0) {
        deleteFileIndices.value.forEach((index) => {
          data.append("deletefiles[]", index.toString());
        });
      }

      if (document.value.coverPicture) {
        if (document.value.coverPicture.raw) {
          data.append("coverPicture", document.value.coverPicture.raw);
        } else if (document.value.coverPicture instanceof File) {
          data.append("coverPicture", document.value.coverPicture);
        }
      }

      data.append(
        "deleteCoverPicturePermanently",
        deleteCoverPicturePermanently.value.toString()
      );
      data.append("deleteCoverPicture", deleteCoverPicture.value.toString());

      const config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent: any) => {
          uploadPercentage.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      return submitEdit(data, config);
    };

    const submit = () => {
      event("Edit document", {
        event_category: "Document",
        event_label: "Document section",
        value: 1,
      });
      const allFilesHaveUrl = document.value.resources.every(
        (file) => file.url
      );

      // Check if cover picture needs update
      const hasCoverPictureChanges =
        deleteCoverPicturePermanently.value ||
        deleteCoverPicture.value ||
        document.value.coverPicture?.raw !== undefined;

      if (allFilesHaveUrl && !hasCoverPictureChanges) {
        editDocumentNoFiles();
      } else {
        editDocumentWithFiles();
      }
    };

    const submitEdit = async (data: any, config?: any) => {
      if (!submitButtonRef.value || !cancelButtonRef.value) {
        return;
      }

      isSubmitting.value = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      try {
        await (ApiService.patch as any)(
          `/library/item/${documentID}`,
          data,
          config
        );

        resetAfterSubmit();

        await Swal.fire({
          text: t("document.editedDocumentInfo"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("document.okay"),
          customClass: { confirmButton: "btn btn-primary" },
        });

        router.push(`/document/${documentID}`);
      } catch (err: any) {
        Swal.fire({
          text:
            err.response?.status === 413
              ? t("course.filesTooLarge")
              : t("document.errorText"),
          icon: err.response?.status === 413 ? "warning" : "error",
          buttonsStyling: false,
          confirmButtonText: t("document.tryAgain"),
          customClass: { confirmButton: "btn fw-bold btn-light-danger" },
        });
      } finally {
        isSubmitting.value = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    return {
      t,
      documentID,
      subjects,
      modules,
      upload,
      document,
      validationSchema,
      submit,
      submitButtonRef,
      handleExceedCover,
      handleExceed,
      deleteFileIndices,
      uploadPercentage,
      customColorMethod,
      store,
      loadingSktelton,
      removeCoverPicture,
      validateCoverPicture,
      resetAfterSubmit,
      handleFileRemove,
      handleFileChange,
      customHttpRequest,
      levels,
      isSubmitting,
      cancelButtonRef,
      editorOptions,
    };
  },
});
